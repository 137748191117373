import {useState, useContext, useRef, useEffect, useMemo} from "react";
import axios from "axios";
import AxiosContext from "./axiosContext";
import * as moment from "moment";
import cookie from "react-cookies";
import * as Constants from "../constants";

const useAxios = (url, method, payload) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState("");
    const [loaded, setLoaded] = useState(false);
    const contextInstance = useContext(AxiosContext);
    const instance = useMemo(() => {
      return contextInstance || axios;
    }, [contextInstance]);
    const controllerRef = useRef(new AbortController());
    const cancel = () => {
      controllerRef.current.abort();
    };
  
    useEffect(() => {
      (async () => {
        try {
          const response = await instance.request({
            signal: controllerRef.current.signal,
            data: payload,
            method,
            url,
          });
  
          console.log(response.data);
          setData(response.data);

          if (response.data && response.data.token) {
            cookie.save(Constants.ACCESS_TOKEN, data.token, {
              expires: moment().add(1, "year").toDate(),
            });
          }
        } catch (error) {
          console.log(error);
          setError(error.message);
        } finally {
          setLoaded(true);
        }
      })();
    }, []);
  
    return { cancel, data, error, loaded };
  };

  export default useAxios;