import React from 'react'
import "./particulars.css";

// Exporting Dashboard component with routing.
export default function Particulars({ amenityIndex, amenityType, particulars, handleBookingCalculations }) {

    return (
        <React.Fragment>
            {particulars.map((particular, partIndex) =>
                <div className='row particulars-row' key={amenityType + partIndex}>
                    
                    <div className='col-sm-9'>
                        <div className='row particulars-row'>
                            <div className='col-sm-4'>
                                <label className='form-label' htmlFor={amenityType + "-" + particular.type}>{particular.label}:</label>
                            </div>
                            <div className='col-sm-8'>
                                <div className="inner-addon right-addon">
                                    <i className="glyphicon"> <span>x </span>
                                    <span>{particular.charges}</span></i>
                                    <input id={amenityType + "-" + particular.type}
                                        className='form-control amount-alignment' type='text'
                                        name={particular.name}
                                        onChange={handleBookingCalculations(amenityIndex, partIndex)}
                                    />
                                </div>
                                
                            </div>
                            
                        </div>
                    </div>
                    <div className='col-sm-3'>
                        <input className='form-control amount-alignment' type='text'
                                value={particular.amount} disabled={true} />
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}
