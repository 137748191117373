import React from "react";
import "./App.css";
import 'react-toastify/dist/ReactToastify.css';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Dashboard from "./components/dashboard/dashboard";
import PrivateRoute from "./services/private-route";
import { ToastContainer } from 'react-toastify';
import AxiosInstanceProvider from "./api/axiosInstanceProvider"
import Login from "./components/login/login";
import Signup from "./components/signup/signup";
import SelfBookingWrapper from "./components/booking/selfBookingWrapper";
// Returns App function with routing.

function App() {
  return (
      <AxiosInstanceProvider
        config={{ baseURL: "https://www.qa1-booking-app.sowermate.com/auth-api/" }}
      >
        <Router>
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/signup">
            <Signup />
          </Route>
          <Route path="/self-ticketing">
            <SelfBookingWrapper />
          </Route>
          <PrivateRoute component={Dashboard} isLogin={false} path="/" ></PrivateRoute>
        </Switch>
      </Router>
        <ToastContainer autoClose={15000} />
      </AxiosInstanceProvider>
  );
}

export default App;
