import React from "react";
import { Route, Redirect } from "react-router-dom";
import * as Constant from "../constants";
import cookie from "react-cookies";

// Exporting private route function for validating routes.
function PrivateRoute({ component: Component, ...rest }) {
  let data = cookie.load(Constant.ACCESS_TOKEN);
  console.log(data);
  return (
        <Route {...rest} render={props => ( <Component {...props} />)}></Route>
    );

   /* return (
        <Route {...rest} render={props => (
            (data) ? <Component {...props} /> : <Redirect to="/login" />
        )}></Route>
    );*/
};
export default PrivateRoute;
