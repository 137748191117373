import axios from "axios";
import * as moment from "moment";
import cookie from "react-cookies";
import * as Constants from "../constants";

const API_URL = "https://www.qa1-booking-app.sowermate.com/auth-api/";
const register = (username, email, password) => {
  return axios.post(API_URL + "signup", {
    username,
    email,
    password,
  });
};
const login = (email, password) => {
  return axios
    .post(API_URL + "login.php", {
      email,
      password,
    })
    .then((response) => {
        console.log(response);
        if (response.data && response.data.token) {
            cookie.save(Constants.ACCESS_TOKEN, response.data.token, {
              expires: moment().add(1, "year").toDate(),
            });
          }
      return response.data;
    });
};
const logout = () => {
  localStorage.removeItem("user");
};
const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};
const AuthService = {
  register,
  login,
  logout,
  getCurrentUser,
};
export default AuthService;