import React, { useEffect, useState } from "react"
import * as Constants from "../../constants";
import useAxios from "../../api/useAxios";
import { API_PAYTM_CONFIG } from '../../urls';
import PaytmChecksum from "./paytmChecksum";

const https = require('https');

export function PaytmButton({ txnAmount, postTicketInfo }) {

    const [paymentData, setPaymentData] = useState({
        token: "",
        order: "",
        mid: "",
        amount: ""
    });

    const { data, error, loaded } = useAxios(
        API_PAYTM_CONFIG,
        Constants.REQUEST_POST, null
    );

    const [loading, setLoading] = useState(false);
    const [transactionStatus, setTransactionStatus] = useState(null);

    useEffect(() => {
        console.log(data);
        if (txnAmount === 0) {
            //initialize once
        } else {
            let newPayment = paymentData;
            newPayment.amount = txnAmount;
            setPaymentData({
                ...paymentData,
                newPayment
            })
            console.log(paymentData);
            initialize();
        }
    }, [txnAmount]);

    useEffect(() => {
        console.log(transactionStatus);
        if(transactionStatus != null && transactionStatus.STATUS === "TXN_SUCCESS") {
            if (window.Paytm && window.Paytm.CheckoutJS) {
                window.Paytm.CheckoutJS.close();
                postTicketInfo();
            }
        }
    }, [transactionStatus]);

    const initialize = () => {
        let orderId = 'Order_' + new Date().getTime();

        // Sandbox Credentials
        let mid = data.paytmMerchantId; // Merchant ID
        let mkey = data.paytmMerchantKey; // Merhcant Key
        let amount = txnAmount;
        var paytmParams = {};

        paytmParams.body = {
            "requestType": "Payment",
            "mid": mid,
            "websiteName": "WEBSTAGING",
            "orderId": orderId,
            "callbackUrl": "https://merchant.com/callback",
            "txnAmount": {
                "value": amount,
                "currency": "INR",
            },
            "userInfo": {
                "custId": '1001',
            }
        };

        PaytmChecksum.generateSignature(JSON.stringify(paytmParams.body), mkey).then(function (checksum) {
            console.log(checksum);
            paytmParams.head = {
                "signature": checksum
            };

            var post_data = JSON.stringify(paytmParams);

            var options = {
                /* for Staging */
                hostname: 'securegw-stage.paytm.in',

                /* for Production */
                // hostname: 'securegw.paytm.in',

                port: 443,
                path: `/theia/api/v1/initiateTransaction?mid=${mid}&orderId=${orderId}`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Content-Length': post_data.length
                }
            };

            var response = "";
            var post_req = https.request(options, function (post_res) {
                post_res.on('data', function (chunk) {
                    response += chunk;
                });
                post_res.on('end', function () {
                    console.log('Response: ', response);
                    // res.json({data: JSON.parse(response), orderId: orderId, mid: mid, amount: amount});
                    setPaymentData({
                        ...paymentData,
                        token: JSON.parse(response).body.txnToken,
                        order: orderId,
                        mid: mid,
                        amount: txnAmount
                    })
                });
            });

            post_req.write(post_data);
            post_req.end();
        });
    }

    const makePayment = () => {
        setLoading(true);
        var config = {
            "root": "",
            "style": {
                "bodyBackgroundColor": "#fafafb",
                "bodyColor": "",
                "themeBackgroundColor": "#0FB8C9",
                "themeColor": "#ffffff",
                "headerBackgroundColor": "#284055",
                "headerColor": "#ffffff",
                "errorColor": "",
                "successColor": "",
                "card": {
                    "padding": "",
                    "backgroundColor": ""
                }
            },
            "data": {
                "orderId": paymentData.order,
                "token": paymentData.token,
                "tokenType": "TXN_TOKEN",
                "amount": paymentData.amount /* update amount */
            },
            "payMode": {
                "labels": {},
                "filter": {
                    "exclude": []
                },
                "order": [
                    "CC",
                    "DC",
                    "NB",
                    "UPI",
                    "PPBL",
                    "PPI",
                    "BALANCE"
                ]
            },
            "website": "WEBSTAGING",
            "flow": "DEFAULT",
            "merchant": {
                "mid": paymentData.mid,
                "redirect": false
            },
            "handler": {
                "transactionStatus": function transactionStatus(paymentStatus) {
                    console.log("paymentStatus => ", paymentStatus);
                    setLoading(false);
                    setTransactionStatus(paymentStatus);
                },
                "notifyMerchant": function notifyMerchant(eventName, data) {
                    console.log("Closed");
                    setLoading(false);
                }
            }
        };

        if (window.Paytm && window.Paytm.CheckoutJS) {
            // initialze configuration using init method
            window.Paytm.CheckoutJS.init(config).then(function onSuccess() {
                console.log('Before JS Checkout invoke');
                // after successfully update configuration invoke checkoutjs
                window.Paytm.CheckoutJS.invoke();
            }).catch(function onError(error) {
                console.log("Error => ", error);
            });
        }
    }

    return (
        <button onClick={makePayment} className="btn btn-primary btn-block" disabled={loading || !loaded}>
            {(loading || !loaded) && (
                <span className="spinner-border spinner-border-sm"></span>
            )}
            <span>Pay with PayTM</span>
        </button>
    )
}