import React from 'react';
import "./booking.css";
import Booking from './booking';

// Exporting Dashboard component with routing.
export default function SelfBookingWrapper() {

    return (
        <section>
            <Booking />
        </section>
    );
}
