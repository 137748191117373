
// Login
export const LOGIN_REQUESTED = "LOGIN_REQUESTED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const ERROR_USERNAME_EXIST = "ERROR_USERNAME_EXIST";
export const SUCCESS_USER_CREATED = "SUCCESS_USER_CREATED";
export const USER_DETAILS = "USER_DETAILS";
// Login
export const TICKET_INFO_REQUESTED = "TICKET_INFO_REQUESTED";
export const TICKET_INFO_SUCCESS = "TICKET_INFO_SUCCESS";
export const TICKET_INFO_FAILED = "TICKET_INFO_FAILED";

export const PAYTM_CONFIG_REQUESTED = "PAYTM_CONFIG_REQUESTED";
export const PAYTM_CONFIG_SUCCESS = "PAYTM_CONFIG_SUCCESS";
export const PAYTM_CONFIG_FAILED = "PAYTM_CONFIG_FAILED";

export const LOGOUT = "LOGOUT";
export const LOGOUT_REQUESTED = "LOGOUT_REQUESTED";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";


// API RELETED

export const ACCESS_DENIED = "ACCESS_DENIED";
export const API_ERROR = "API_ERROR";
export const REQUEST_GET = "GET";
export const REQUEST_DELETE = "DELETE";
export const REQUEST_POST = "POST";
export const REQUEST_PUT = "PUT";
export const REQUEST_PATCH = "PATCH";


// USER TOKEN and DATA
export const ACCESS_TOKEN = "ACCESS_TOKEN";

export const WEEKLY = 'Weekly Check-Ins';
export const MONTHLY = '1:1';


export const MOMENT_DATE_FORMAT = "MM-DD-YYYY hh:mm a";
export const PENDING_DATE_FORMAT = "YYYY-MM-DD hh:mm a";
export const NO_RESPONSE_ERROR = "Not responded yet.";