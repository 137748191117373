import {useState, useContext, useRef, useEffect, useMemo} from "react";
import axios from "axios";
import AxiosContext from "./axiosContext";
import * as Constants from "../constants";
import { v4 as uuid } from 'uuid';
import { toast } from "react-toastify";

const useTicketInfo = (url, method, payload) => {
  const [ticketInfo, setTicketInfo] = useState(null);
  const [displayVisitingDate, setDisplayVisitingDate] = useState(new Date());
  const [displayBookingDate, setDisplayBookingDate] = useState(new Date());
  const [error, setError] = useState("");
    const [loaded, setLoaded] = useState(false);
    const contextInstance = useContext(AxiosContext);
    const instance = useMemo(() => {
      return contextInstance || axios;
    }, [contextInstance]);

    const controllerRef = useRef(new AbortController());
    const cancel = () => {
      controllerRef.current.abort();
    };
  
    useEffect(() => {
      
    }, []);

      const fetchTicketInfo = async () => {
        try {
          const response = await instance.request({
            signal: controllerRef.current.signal,
            data: payload,
            method,
            url,
          });

          setDisplayBookingDate(new Date());
          console.log('Ticket Info:', response.data);
      
          let formattedDate = `${displayBookingDate.getDate()}/${displayBookingDate.getMonth() + 1}/${displayBookingDate.getFullYear()}`;
          console.log(formattedDate, ticketInfo);
      
          setTicketInfo(response.data.config);
          setTicketInfo({
            ...ticketInfo,
            bookingDate: formattedDate
          });
          console.log(formattedDate, ticketInfo);
      
          // Generate and set unique id for ticket
          const uniqueId = uuid();
          const tmpTicketId = uniqueId.replace(/-/g, '');
          console.log(uniqueId, tmpTicketId);
          setTicketInfo({
            ...response.data.config,
            ticketId: tmpTicketId
          });
      
          
        } catch (error) {
          console.log(error);
          setError(error.message);
        } finally {
          setLoaded(true);
        }
      };

      const handleBookingCalculations = (amenityIndex, partIndex) => event => {
        let newTicketInfo = ticketInfo; // copying the old datas array
        newTicketInfo.amenities[amenityIndex].particulars[partIndex].count = event.target.value;
        newTicketInfo.amenities[amenityIndex].particulars[partIndex].amount =
            newTicketInfo.amenities[amenityIndex].particulars[partIndex].charges * event.target.value;
        let total = 0;
        newTicketInfo.amenities.forEach((amenityInfo) => {
            amenityInfo.particulars.forEach((particular) => {
                total += particular.amount;
            });
        });
        newTicketInfo.paymentSubTotal = total;
        newTicketInfo.paymentTotal = (total + (total * (newTicketInfo.portalFeesAndServiceTax / 100)));
        setTicketInfo({
            ...ticketInfo,
            paymentTotal: (total + (total * (newTicketInfo.portalFeesAndServiceTax / 100)))
        });
        setTicketInfo({
          ...ticketInfo,
          createdDttm: new Date()
      });
  
    };
    const updateCustomerName = () => event => {
      console.log(ticketInfo);
      console.log(event.target.value);
      setTicketInfo({
          ...ticketInfo,
          customerName: event.target.value
      });
    };

    const updateVisitingDate = () => value => {
      console.log(value);
      let dateddMMY = `${value.getDate()}/${value.getMonth() + 1}/${value.getFullYear()}`;
      setTicketInfo({
          ...ticketInfo,
          visitingDate: dateddMMY
      });
      let ticketInfoCopy = ticketInfo;
      setDisplayVisitingDate(value);
      console.log(ticketInfoCopy);
      
    };
    
    const updateCustomerWhatsAppNumber = () => event => {
      console.log(ticketInfo);
      console.log(event.target.value);
      setTicketInfo({
          ...ticketInfo,
          whatsAppNumber: event.target.value
      });
    };

    const postTicketInfo = async () => {
      console.log(ticketInfo);
      try {
        const response = await instance.request({
          signal: controllerRef.current.signal,
          data: ticketInfo,
          method: Constants.REQUEST_POST,
          url: "https://www.qa1-booking-app.sowermate.com/auth-api/send-ticket.php",
        });

        console.log('Message:', response);
        /*let msg = '<div>' +
        '<div>' + response.data.message + '</div>' +
        '<div><b>Ticket Id: </b>' + response.data.ticketId + '</div>' +
        '<div><b>Message Id: </b>' + response.data.messageId + '</div></div>';*/
        let msg = '' + response.data.message + '. Ticket Id: ' + response.data.ticketId;
        toast.success(msg);
      } catch (error) {
        console.log(error);
        setError(error.message);
      } finally {
        setLoaded(true);
      }
    };
    const api ={
      fetchTicketInfo,
      updateVisitingDate,
      updateCustomerName,
      handleBookingCalculations,
      updateCustomerWhatsAppNumber,
      postTicketInfo
    };
    return { cancel, ticketInfo, displayBookingDate, displayVisitingDate, error, loaded, api };
  };

  export default useTicketInfo;