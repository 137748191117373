
const UMS = "https://www.qa1-booking-app.sowermate.com/auth-api/";

export const API_LOGIN = "login.php";

export const API_TICKET_INFO_POST = "send-ticket.php";
export const API_TICKET_INFO = "get-ticketing-app-config.php";
export const API_PAYTM_CONFIG = "get-paytm-config.php";

export const API_LOGOOUT = UMS + "/api/auth/logout";
export const API_CHANGE_PASSWORD = UMS + "/api/auth/change-password";
export const API_REVIEWS = UMS + "/api/reviews/";
export const API_GET_SUPERVISEE_HIERARCHY_REVIEW = UMS + "/api/reviews-loggedin/";
export const API_RESPONSES = UMS + "/api/responses/";
export const API_LAST_REVIEW = UMS + "/api/last-review/";
export const API_GET_SUPERVISEES = UMS + "/api/auth/supervisees/";
export const API_GET_SUPERVISEES_REVIEW = UMS + "/api/auth/supervisees-reviews/";
export const API_GET_SUPPERVISEE_HIERARCHY = UMS + "/api/auth/hierarchy/";
export const API_GET_ALL_REPORTS = UMS + "/api/all-reports/";
export const PM_API_GET_ORG_CHART = UMS + "/api/auth/org-chart/";
export const API_ARCHIVE_REVIEW = UMS + "/api/archived-reviews/";
export const API_GET_ALL_SUPERVISORS = UMS + "/api/auth/supervisors";
export const API_SEND_EMAIL = UMS + "/api/auth/request-reset-email/";
export const API_CHECK_CREDENTIALS = UMS + "/api/auth/password-reset/";
export const API_GET_ALL_EMPLOYEES = UMS + "/api/auth/employee_list/";
export const API_GET_ALL_DEPARTMENTS = "/actiontracker/departments/";
export const API_GET_ALL_STAKEHOLDERS = "/actiontracker/stake_holders/";

export const API_GET_ALL_PROJECTS = "/actiontracker/projects/";
export const API_PROJECTS = "/actiontracker/projects/"
export const API_TASKS = "actiontracker/tasks/";
export const API_TASK_LIST = "actiontracker/taskListFilter/"

export const API_FILTER_TASK_LIST = "actiontracker/taskListFilter/"
export const API_GET_PENDING_EMPLOYEES = UMS + "/api/auth/pending-employees/";

