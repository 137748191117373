import React from 'react'

import {
    Route
} from "react-router-dom";
import { Layout } from "antd";
import CustomHeader from '../header/header';
import BookingWrapper from '../booking/bookingWrapper';
const { Content } = Layout;

// Exporting Dashboard component with routing.
export default function Dashboard() {

    return (
        <Layout>
            <CustomHeader />
            <Content>
                <Route path="/" component={BookingWrapper}>
                </Route>
            </Content>
        </Layout>
    );

}
