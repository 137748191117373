import React from 'react';

import "./amenities.css";
import Amenity from '../amenity/amenity';

// Exporting Dashboard component with routing.
export default function Amenities({ amenities, handleBookingCalculations }) {
    
    return (
        <React.Fragment>
            <div className="card mb-1 bg-white rounded">
                        <div className="card-body content-header">
                            <div className="row justify-content-center amenities">
                                <div className='col-sm-1'>Sr. No.</div>
                                <div className='col-sm-2'>
                                    Amenities
                                </div>
                                <div className='col-sm-4 amenity-name'></div>
                                <div className='col-sm-4'>
                                    Particulars
                                </div>
                                <div className='col-sm-1'>
                                    Amount
                                </div>
                            </div>
                        </div>
                    </div>
            {amenities.map((amenityInfo, amenityIndex) =>
                <Amenity key={amenityIndex}
                    amenityIndex={amenityIndex}
                    amenityInfo={amenityInfo}
                    handleBookingCalculations={handleBookingCalculations}>
                </Amenity>
            )}
        </React.Fragment>
    );

}
