import React, {useRef, useEffect} from "react";
import axios from "axios";
import * as Constant from "../constants";
import cookie from "react-cookies";
import AxiosContext from "./axiosContext";

const tokenInterceptor =  (config) => {
    // Do something before request is sent
    /**let data = cookie.load(Constant.ACCESS_TOKEN);
    config.headers = { 
        'Authorization': `Bearer ${data}`,
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
    }*/

    console.log(config)
    return config;
  }

const AxiosInstanceProvider = ({
  config = {},
  requestInterceptors = [tokenInterceptor],
  responseInterceptors = [],
  children,
}) => {
  const instanceRef = useRef(axios.create(config));

  useEffect(() => {
    /*requestInterceptors.forEach((interceptor) => {
      instanceRef.current.interceptors.request.use(
        interceptor
      );
    });
    responseInterceptors.forEach((interceptor) => {
      instanceRef.current.interceptors.response.use(
        interceptor
      );
    });*/

    instanceRef.current.interceptors.request.use(request => {
      // add auth header with jwt if account is logged in and request is to the api url
      //const account = accountService.accountValue;
      //const isLoggedIn = account?.token;
      //const isApiUrl = request.url.startsWith(process.env.REACT_APP_API_URL);

     // if (isLoggedIn && isApiUrl) {
     //     request.headers.common.Authorization = `Bearer ${account.token}`;
    //  }
      let data = cookie.load(Constant.ACCESS_TOKEN);
      console.log('ACCESS_TOKEN', data);
      
      if (data) {
        request.headers.common.Authorization = `Bearer ${data}`;
      } 
      return request;
  });
  }, []);

  return (
    <AxiosContext.Provider value={instanceRef.current}>
      {children}
    </AxiosContext.Provider>
  );
};

export default AxiosInstanceProvider;