import React from 'react';
import 'react-modal-overlay/dist/index.css';
import { Layout } from 'antd';
import "./booking.css";
import Booking from './booking';

// Exporting Dashboard component with routing.
export default function BookingWrapper() {

    return (
        <Layout>
            <Booking />
        </Layout>
    );
    
}
