import React from 'react';

import Particulars from '../particular/particulars';
import "./amenity.css";

// Exporting Dashboard component with routing.
export default function Amenity({ amenityIndex, amenityInfo, handleBookingCalculations }) {

    return (
        <React.Fragment>
            <div className="card mb-1 bg-white rounded">
                <div className="card-body content-body">
                    <div className="row justify-content-center amenities">
                        <div className='col-sm-1 amenity-name'>{amenityIndex + 1}</div>
                        <div className='col-sm-6'>
                            <div className="row">
                                    <img className="col-sm-4" id={amenityInfo.name}
                                        src={amenityInfo.imageUrl}
                                        alt={amenityInfo.name} />
                                
                                <div className='col-sm-8 amenity-name'>
                                    {amenityInfo.name}
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-5'>
                            <Particulars
                                amenityIndex={amenityIndex}
                                amenityType={amenityInfo.type}
                                particulars={amenityInfo.particulars}
                                handleBookingCalculations={handleBookingCalculations} >
                            </Particulars>
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
