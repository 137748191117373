import React, { useState, useRef } from "react";
import Form from "react-validation/build/form";
import { Link, withRouter, useHistory } from 'react-router-dom';
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import AuthService from "../../services/auth.service";
import "./login.css";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

/**
 * Exporting login component.
 * It contains login process for user.
 */
 function Login() {
   const history = useHistory();
  const form = useRef();
  const checkBtn = useRef();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };
  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setMessage("");
    setLoading(true);
    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0) {
      AuthService.login(email, password).then(
        () => {
          history.push('/');
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setLoading(false);
          setMessage(resMessage);
        }
      );
    } else {
      setLoading(false);
    }
  };

  return (
    <section className="loginBackGround">
      <div className="container min-vh-100">
        <div className="row min-vh-100 justify-content-center align-items-center">
          <div className="col-10 col-md-7 col-lg-4">
            <Form onSubmit={handleLogin} className="justify-content-center" ref={form}>
              <div className="text-center">
                <h3>Sign In</h3>
              </div>
              <div className="form-group">
                <label>Email address</label>
                <Input
                  type="email"
                  className="form-control"
                  value={email}
                  onChange={onChangeEmail}
                  name="email"
                  validations={[required]}
                  placeholder="Enter email"
                />
              </div>

              <div className="form-group">
                <label>Password</label>
                <Input
                  type="password"
                  className="form-control"
                  value={password}
                  onChange={onChangePassword}
                  name="password"
                  validations={[required]}
                  placeholder="Enter password"
                />
              </div>
              <button type="submit" className="btn btn-primary btn-block" disabled={loading}>
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Login</span>
              </button>
              {message && (
                <div className="form-group">
                  <div className="alert alert-danger" role="alert">
                    {message}
                  </div>
                </div>
              )}
              <CheckButton style={{ display: "none" }} ref={checkBtn} />

              <p className="forgot-password text-center">
                <Link className="nav-link" to="/signup">
                  Signup?
                </Link>
              </p>
            </Form>
          </div>
        </div>
      </div>
    </section>
  );

}


export default withRouter(Login)