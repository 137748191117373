import React, { useEffect, useState } from 'react';
import { Modal } from 'react-modal-overlay';
import 'react-modal-overlay/dist/index.css';
import { Layout } from 'antd';
import "./booking.css";
import Amenities from '../amenities/amenities';

import { PaytmButton } from '../paytm-button/paytmButton';
import * as Constants from "../../constants";
import { API_TICKET_INFO } from '../../urls';
import useTicketInfo from '../../api/useTicketInfo';
import DatePicker from 'react-date-picker';

const { Content } = Layout;

// Exporting Dashboard component with routing.
export default function Booking() {

    const { ticketInfo, displayBookingDate, displayVisitingDate, error, loaded, api } = useTicketInfo(
        API_TICKET_INFO,
        Constants.REQUEST_POST, null);

    const [isPaymentOverlay, setIsPaymentOverlay] = useState(false)

    useEffect(() => {
        api.fetchTicketInfo();
    }, []);


    if (loaded) {
        return (

            <Content className='container content'>
                <div className="card mb-1 bg-white rounded">
                    <div className="card-body content-body">
                        <div className="container">
                            <div className="row">
                                <div className='col-12'>
                                    <h4 style={{ textAlign: 'center' }}>Jungle Safari: Ticket Booking</h4>
                                </div>

                            </div>

                            <div className="row">
                                <div className='col-sm-6'>
                                    <div className="row">
                                        <div className='col-sm-3'>
                                            <label className='form-label'><b>Ticket Id: </b></label>
                                        </div>
                                        <div className='col-sm-9'>
                                            <span>{ticketInfo.ticketId}</span>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className='col-sm-6'>
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <label className='form-label'><b>Booking Date: </b></label>
                                        </div>
                                        <div className='col-sm-8'>
                                            <DatePicker
                                                onChange={api.updateVisitingDate()}
                                                disabled={true}
                                                format="dd/MM/y"
                                                value={displayBookingDate} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className='col-sm-6'>
                                    <div className="row">
                                        <div className='col-sm-3'>
                                            <label className='form-label'><b>Visiting Date: </b></label>
                                        </div>
                                        <div className='col-sm-9'>
                                            <DatePicker
                                                onChange={api.updateVisitingDate()}
                                                format="dd/MM/y"
                                                value={displayVisitingDate} />
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className='col-sm-6'>
                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <label className='form-label'><b>Visiting Time: </b></label>
                                        </div>
                                        <div className='col-sm-8'>
                                            <span>{ticketInfo.allowedVisitTime}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <Amenities
                    amenities={ticketInfo.amenities}
                    handleBookingCalculations={api.handleBookingCalculations}>
                </Amenities>

                <div className="card mb-1 bg-white rounded">
                    <div className="card-body content-body">
                        <div className="row justify-content-center amenities">
                            <div className='col-sm-9'>
                                <div className='payment-total-section-label'>
                                    <label className='form-label' htmlFor='total'>Payment Sub Total:</label>
                                </div>
                            </div>
                            <div className='col-sm-3'>
                                <input id="paymentSubTotal" className='form-control amount-alignment' type='number' value={ticketInfo.paymentSubTotal} disabled={true} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card mb-1 bg-white rounded">
                    <div className="card-body content-body">
                        <div className="row justify-content-center amenities">
                            <div className='col-sm-9'>
                                <div className='payment-total-section-label'>
                                    <label className='form-label' htmlFor='portalFeesAndServiceTax'>Portal Fees And Service Tax (%):</label>
                                </div>
                            </div>
                            <div className='col-sm-3'>
                                <input id="portalFeesAndServiceTax" className='form-control amount-alignment' type='number' value={ticketInfo.portalFeesAndServiceTax} disabled={true} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card mb-1 bg-white rounded">
                    <div className="card-body content-body">
                        <div className="row justify-content-center amenities">
                            <div className='col-sm-9'>
                                <div className='payment-total-section-label'>
                                    <label className='form-label' htmlFor='paymentTotal'>Payment Total:</label>
                                </div>
                            </div>
                            <div className='col-sm-3'>
                                <input id="paymentTotal" className='form-control amount-alignment' type='number' value={ticketInfo.paymentTotal} disabled={true} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card mb-1 bg-white rounded">
                    <div className="card-body content-body">
                        <div className="row justify-content-center amenities">
                            <div className='col-sm-9'>
                                <div className='payment-total-section-label'>
                                    <label className='form-label' htmlFor='paymentTotal'>Customer WhatsApp Number (including country code):</label>
                                </div>
                            </div>
                            <div className='col-sm-3'>
                                <input
                                    id={ticketInfo.whatsAppNumber}
                                    className='form-control'
                                    type='text'
                                    name={ticketInfo.whatsAppNumber}
                                    onKeyUp={api.updateCustomerWhatsAppNumber()}
                                    placeholder={ticketInfo.whatsAppNumber} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card mb-1 bg-white rounded">
                    <div className="card-body content-body">
                        <div className="row justify-content-center amenities">
                            <div className='col-sm-9'>
                                <div className='payment-total-section-label'>
                                    <label className='form-label' htmlFor='paymentTotal'>Customer Name:</label>
                                </div>
                            </div>
                            <div className='col-sm-3'>
                                <input
                                    id={ticketInfo.whatsAppNumber}
                                    className='form-control'
                                    type='text'
                                    name={ticketInfo.whatsAppNumber}
                                    onKeyUp={api.updateCustomerName()}
                                    placeholder={ticketInfo.customerName} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card mb-1 bg-white rounded">
                    <div className="card-body">
                        <div className="row">
                            <div className='col-sm-7'>
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            </div>
                            <div className='col-sm-5'>
                                <div className='row'>
                                    <div className='col-sm-5'>
                                        <PaytmButton txnAmount={ticketInfo.paymentTotal} postTicketInfo={api.postTicketInfo} />
                                    </div>
                                    <div className='col-sm-7'>
                                        <button className='btn btn-primary btn-block'
                                            onClick={api.postTicketInfo}>Confirm & Send Receipt</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={isPaymentOverlay} closeModal={() => setIsPaymentOverlay(false)}>
                    <h4> The ticket is sent to customer on WhatsApp number</h4>
                </Modal>
            </Content>
        );

    }
    return (<div>Loading...</div>);
}
